import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import signNames from 'config/constants/signNames';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { EmojiCardItemType } from '../EmojiCard';

import s from './styles.css';

interface IEmoForecast {
  widgetData: EmoWidgetDataType
  forecastData: EmojiCardItemType
  withSpecial?: boolean
  splitName: string
}

enum Reactions {
  Like = 'like',
  Dislike = 'dislike'
}

export const EmoForecast: React.FC<IEmoForecast> = React.memo(({
  widgetData,
  forecastData,
  splitName,
  withSpecial,
}) => {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const {
    top100Prefix,
  } = useTop100Context();

  const top100Postfix = `${splitName}::${widgetData.sign}::${widgetData.date}::${forecastData.name}`;

  const [reaction, setReaction] = useState<Reactions>();

  const forecastText = useMemo(() => {
    if (Object.keys(widgetData)?.length && forecastData) {
      const dataKey = forecastData.textDataKey as keyof EmoWidgetDataType;
      return widgetData[dataKey];
    }

    return '';
  }, [forecastData, widgetData]);

  const signDativeName = signNames.zodiacDative[widgetData.sign as keyof typeof SIGN];
  const titleByName = {
    fine:    `Как ${signDativeName} сделать день еще лучше`,
    doubt:   `Совет ${signDativeName}, как избежать ошибок`,
    support: `Что поможет ${signDativeName} пережить этот период`,
    event:   `Что делать ${signDativeName}, чтобы всё прошло отлично`,
  };
  const forecastTitle = titleByName[forecastData.name];

  const reactionHandleClick = (reactionName: Reactions) => {
    setReaction(reactionName);
  };

  return (
    <div className={cn(
      s.forecast,
      isMobile && s.mobile,
      withSpecial && s.special,
      withSpecial && s[forecastData.name],
    )}
    >
      <Typography
        variant="h3"
        className={s.forecastTitle}
      >
        {forecastTitle}
      </Typography>
      <Typography
        variant="defaultRegular"
        className={s.forecastText}
      >
        {forecastText}
      </Typography>
      <div className={s.reactions}>
        <Typography
          variant="defaultSemiBold"
          className={s.reactionsText}
        >
          Это было полезно?
        </Typography>
        <button
          type="button"
          aria-label="Нравиться"
          disabled={reaction === Reactions.Dislike}
          className={cn(
            s.loveButton,
            reaction === Reactions.Like && s.active,
          )}
          onClick={() => reactionHandleClick(Reactions.Like)}
          {...getTop100Markup(isMobile, top100Prefix, `${top100Postfix}::like`)}
        >
          <Icon
            id="love"
            className={s.loveIcon}
          />
        </button>
        <button
          type="button"
          aria-label="Не нравиться"
          disabled={reaction === Reactions.Like}
          className={cn(
            s.dislikeButton,
            reaction === Reactions.Dislike && s.active,
          )}
          onClick={() => reactionHandleClick(Reactions.Dislike)}
          {...getTop100Markup(isMobile, top100Prefix, `${top100Postfix}::dislike`)}
        >
          <Icon
            id="dislike"
            className={s.dislikeIcon}
          />
        </button>
      </div>
    </div>
  );
});
